body {
  /* background-color: #FFC1D7; */
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  /* display: flex;
  justify-content: flex-start; */
  /* align-items: center; */
  /* flex-direction: column; */
}
.App {
  /* background-color: #FFC1D7; */
}
.Uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.banner {
  width: 100%;
  text-align: center;
}
.banner img {
  width: 100%;
  height: auto;
}
.logo { 
  margin-top: 20px; /* Adjust as needed */
  text-align: center;
}
.logo img {
  max-width: 300px; /* Adjust as needed */
  height: auto;
}
.custom-button {
  background-color: lightgray;
  color: black;
  border: 2px solid black; /* Solid black outline */
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 20px 2px; /* Adjust as needed */
  cursor: pointer;
  border-radius: 25px; /* Adjust for the rounded corners */
  font-family: Arial, sans-serif;
  width: 200px; /* Adjust as needed */
  box-sizing: border-box; /* Ensures padding doesn't affect width */
  height: 50px; /* Consistent height */
  line-height: 1.2; /* Adjust to vertically center text */
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-button {
  background-color: transparent; /* Make background transparent */
  color: black;
  font-size: 16px;
  cursor: pointer;
  font-family: Arial, sans-serif;
  line-height: 1.2; /* Adjust to vertically center text */
  border: none; /* Remove button border */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px; /* Add padding if necessary */
}
.bottom-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  padding: 10px 0; /* Optional: padding for the container */
}
.signin-button {
  background-color: transparent;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 20px 2px; /* Adjust as needed */
  cursor: pointer;
  font-family: Arial, sans-serif;
  width: 200px; /* Adjust as needed */
  box-sizing: border-box; /* Ensures padding doesn't affect width */
  height: 40px; /* Consistent height */
  line-height: 1.2; /* Adjust to vertically center text */
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-text-input {
  background-color: #FFF;
  color: black;
  border: 2px solid black; /* Solid black outline */
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 20px 2px; /* Adjust as needed */
  border-radius: 5px; /* Adjust for the rounded corners */
  width: 200px; /* Adjust as needed */
  box-sizing: border-box; /* Ensures padding doesn't affect width */
  line-height: 1.2; /* Adjust to vertically center text */
  align-items: center;
  justify-content: center;
}
.custom-multiline-text-input {
  background-color: #FFF;
  color: black;
  border: 2px solid black; /* Solid black outline */
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  padding: 10px 20px;
  margin: 20px 2px; /* Adjust as needed */
  border-radius: 5px; /* Adjust for the rounded corners */
  font-family: Arial, sans-serif;
  width: 300px; /* Adjust as needed */
  box-sizing: border-box; /* Ensures padding doesn't affect width */
  height: 100px; /* Consistent height */
  line-height: 1.2; /* Adjust to vertically center text */
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none;
}
.hidden-fields {
  margin-top: 100px; /* Adjust as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
}

#iframeContainer {
  flex: 1; /* This allows the iframe container to take up the remaining space */
  display: flex;
}

#iframeContainer iframe {
  border: none;
  width: 100%;
  height: 100%;
}

#pageContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#bannerImage {
  width: 100%;
  height: auto;
}